import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"

const LegalPage = () => (
  <Layout>
    <SEO title="Legal Notices and Disclaimers" />
    <div className="bg-midnight w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">

        <div>

        <div className="text-base mb-4 -mt-4">
          <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
          <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Legal Notices and Disclaimers</span>
        </div>
        <h1 className="text-4xl md:text-5xl text-yellow-400 leading-none !mb-0">Legal Notices and Disclaimers</h1>
        </div>
        <div>
        </div>
        </div>
          <div className="m-auto w-full md:w-4/6 container">
              <div className="text-center">
              <div className="inline-block text-left">
                <h2 id="terms-of-use">Terms of Use</h2>
                <ol>
                <li><strong>Introduction</strong>: Welcome to Touchstone District Services (the &quot;Service&quot;). By using the Service, you agree to these Terms of Use (&quot;Terms&quot;). Please read them carefully.</li>
                <li><strong>Consent to Receive Messages</strong>: By providing your cell phone number and checking the box, you consent to receive calls and texts, including autodialed and automated calls and texts with notifications from Touchstone District Services.</li>
                <li><strong>Message Content</strong>: Messages may include updates, voter information, donation requests, and other communications related to the Service.</li>
                <li><strong>Frequency and Opt-out</strong>: Message frequency may vary. You can discontinue receiving messages at any time by replying STOP to any message received from us.</li>
                <li><strong>Assistance</strong>: If you need assistance or have questions, reply HELP to any message received from us.</li>
                <li><strong>Message and Data Rates</strong>: Message and data rates may apply, depending on your carrier&#39;s plan.</li>
                <li><strong>User Conduct</strong>: You agree to use the Service only for lawful purposes and in compliance with all applicable laws.</li>
                <li><strong>Disclaimer</strong>: The Service is provided &quot;as-is&quot; and &quot;as-available.&quot; We disclaim all warranties, express or implied.</li>
                <li><strong>Governing Law</strong>: These Terms shall be governed by and construed in accordance with the laws of the State of Texas, without giving effect to any principles of conflicts of law. Any dispute arising out of or related to these Terms shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association, as amended from time to time, and shall be conducted in [ specify city or county, e.g., Austin, Texas].</li>
                </ol>
                <h2 id="privacy-policy">Privacy Policy</h2>
                <ol>
                <li><strong>Introduction</strong>: Touchstone District Services respects your privacy and is committed to protecting it.</li>
                <li><strong>Collection of Information</strong>: We collect the following information:<ul>
                <li>Your cell phone number, which you provide to us when you opt-in to receive messages.</li>
                <li>Any responses or interactions you have with our messages, such as replying STOP or HELP.</li>
                </ul>
                </li>
                <li><strong>Use of Information</strong>: We use the collected information to:<ul>
                <li>Send you messages, including updates, voter information, donation requests, and other communications related to the Service.</li>
                <li>Improve our services and communicate with you about new features or updates.</li>
                </ul>
                </li>
                <li><strong>Sharing of Information</strong>: We will not share your information with third parties, except as required by law or to provide the Service.</li>
                <li><strong>Security</strong>: We take reasonable measures to protect your information from unauthorized access, disclosure, or destruction.</li>
                <li><strong>Data Retention</strong>: We will retain your information for as long as necessary to provide the Service and comply with applicable laws.</li>
                <li><strong>Changes to this Policy</strong>: We may update this Privacy Policy from time to time. Changes will be posted on our website, and you should review them periodically.</li>
                </ol>
                <p>By using the Service, you acknowledge that you have read, understand, and agree to these Terms of Use and Privacy Policy. If you do not agree, please do not use the Service.</p> <p><strong>Contact Us</strong>: If you have any questions or concerns about these Terms of Use or Privacy Policy, please contact us <Link to="/contact-us">here</Link>.</p>
              </div>
              </div>
              </div>
      </ProseDark>
    </div>
    </div>
  </Layout>
)

export default LegalPage
